// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require('moment');

export default class Utils {
  public static toDate(date: string, time: string, timezone?: string) {
    if (date === null || time === null) {
      return null;
    }

    let parseString = 'YYYY-MM-DD HH:mm';
    let testString = `${date} ${time}`;
    if (timezone) {
      parseString += ' XXX';
      testString += ` ${timezone}`;
      console.log(testString);
    }
    const parsedDate = moment(testString, parseString);
    const currentDate = moment().parseZone();
    const isFuture = parsedDate.isAfter(currentDate, 'day');
    if (!isFuture) {
      return parsedDate.hours() > currentDate.hours() && parsedDate.date() === currentDate.date();
    }
    return isFuture;
  }

  // Debounce function that delays the execution of the passed function
  public static debounce(fn: Function, delay: number) {
    let timeoutId: number | undefined;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        // eslint-disable-next-line prefer-spread
        fn.apply(null, args);
      }, delay);
    };
  }
}
