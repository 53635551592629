import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Cookies from 'js-cookie';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimesCircle,
  faCheckCircle,
  faSync,
  faQuestionCircle,
  faTrashAlt,
  faUserPlus,
  faUserCircle,
  faInfoCircle,
  faCog,
  faUser,
  faUserCheck,
  faFireAlt,
  faDownload,
  faSave,
  faBan,
  faPeopleCarry,
  faExclamationTriangle,
  faCheck,
  faComment,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  BootstrapVueNextResolver,
  createBootstrap,
  BModal,
  BCollapse,
  BToastOrchestrator,
  vBModal,
  vBToggle,
  vBTooltip,
} from 'bootstrap-vue-next';
import { format } from 'date-fns';
import vueDebounce from 'vue-debounce';
import Autocomplete from '@trevoreyre/autocomplete-vue/dist/autocomplete.esm';
import { Skeleton } from 'vue-loading-skeleton';
import router from './router';
import App from './App.vue';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import '@trevoreyre/autocomplete-vue/dist/style.css';
import 'vue-loading-skeleton/dist/style.css';

const pinia = createPinia();
const app = createApp(App);
app.use(pinia);
app.use(Autocomplete);
app.use(BootstrapVueNextResolver);
app.use(createBootstrap());
app.use(vueDebounce);

library.add(
  faTimesCircle,
  faCheckCircle,
  faSync,
  faQuestionCircle,
  faInfoCircle,
  faTrashAlt,
  faUserPlus,
  faUserCircle,
  faCog,
  faUser,
  faUserCheck,
  faFireAlt,
  faDownload,
  faSave,
  faBan,
  faPeopleCarry,
  faExclamationTriangle,
  faCheck,
  faComment,
);

app.component('FontAwesomeIcon', FontAwesomeIcon);
app.component('ContentLoader', Skeleton);
app.component('BModal', BModal);
app.component('BCollapse', BCollapse);
app.component('BToastOrchestrator', BToastOrchestrator);
app.directive('b-modal', vBModal);
app.directive('b-toggle', vBToggle);
app.directive('b-tooltip', vBTooltip);
app.config.globalProperties.$cookies = Cookies;
app.use(router);

app.config.globalProperties.$filters = {
  formatDate: (value) => format(value, 'yyyy-MM-dd HH:mm:ss O'),
};

app.mount('#app');
